import { plans } from './Options';
import { integerFormat, currencyFormat } from './Utils';

const getExtras = (contributors, bankAccounts) => {
  const totalPriceContributors = process.env.GATSBY_PRICE_CONTRIBUTORS * contributors;
  const totalPriceBankAccounts = process.env.GATSBY_PRICE_BANK_ACCOUNTS * bankAccounts;

  return (totalPriceBankAccounts + totalPriceContributors);
};

const getMonthlyPayment = (values) => {
  const { value, currentValue } = values;

  if (Number.isInteger(value)) {
    return value;
  }

  return (value * currentValue);
};

export const getSwitchAccountPrice = (calculatorForm, segment) => {
  const {
    contributors, bankAccounts, monthly,
  } = calculatorForm;

  const extras = getExtras(contributors, bankAccounts);

  const plan = plans[segment];

  let values = plans.Online[monthly];

  if (plan) {
    values = plans[segment][monthly];
  }

  const allCosts = getMonthlyPayment(values);

  const totalPrice = ((extras + allCosts) / 100)
    .toFixed(2)
    .replace('.', ',');

  return totalPrice;
};

export const getEconomyValue = (currentAccountant, planPrice) => {
  const planPriceFormatted = integerFormat(planPrice);
  const currentAccountantFormatted = integerFormat(currentAccountant);

  if (currentAccountantFormatted > planPriceFormatted) {
    const economyValue = currentAccountantFormatted - planPriceFormatted;
    return currencyFormat(economyValue * 12);
  }

  return 0;
};

export const getPlanName = (segment, monthlyRevenue) => {
  if (segment === 'Comércio') return 'Comércio';

  const revenues = Object.keys(plans[segment] || plans.Online);

  const revenuePosition = revenues.findIndex((el) => el === monthlyRevenue);

  if (revenuePosition < 6) return 'Online';
  if (revenuePosition >= 6) return 'Private';
  if (revenuePosition >= 7) return 'Corporate';
};

export const getOpenCompanyPrice = (segment) => {
  const plan = plans[segment];
  let value = 0;
  if (!plan) {
    value = plans.Online['até R$20.000/mês'].value;
  } else {
    value = plan['até R$20.000/mês'].value;
  }

  const totalPrice = (value / 100).toFixed(2).replace('.', ',');
  return totalPrice;
};
