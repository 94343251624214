import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {
  Grid, Typography, Paper, Box
} from '@material-ui/core';
import TooltipInfo from '../atoms/TooltipInfo';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/styles';
import PlanDetails from './PlanDetail';

const scrollBy = () => {
  window.scrollBy(0, -100000);
};

export const InfoCardLanding = ({
  planName,
  noOldPrice,
  type,
  oldPrice,
  newPrice,
  noPrice,
  planDescription,
  planPerks,
  bestChoiceStyle,
  disablePrice,
  purpleFriday,
  previousPlan
}) => {
  const [oldCurrency, oldCents] = (oldPrice / 100).toFixed(2).split('.');
  const [newCurrency, newCents] = (newPrice / 100).toFixed(2).split('.');

  const theme = useTheme();

  const useStyles = makeStyles({
    root: {
      //backgroundColor: 'blue',
      maxWidth: '380px',
      margin: '0 16px',
      '& .MuiCollapse-container': {
        width: '100%',
      },
      '& .MuiCard-root': {
        minHeight: '662px',
        borderRadius: '15px',
      },
      '@media(max-width: 380px)': {
        width: '100%',
      },
    },
    expandButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiButton-label, .MuiSvgIcon-colorPrimary': {
        color: theme.palette.primary.bgLight,
      },
      color: theme.palette.primary.bgLight,
    },
    topBox: {
      display: 'flex',
      flexDirection: 'column',
      height: '45px',
      margin: '-16px -16px 0 -16px',
      // backgroundColor: `${theme.palette.primary.bgLight}40`,
      backgroundColor: `#7c2e7e`,
      '& .MuiTypography-root': {
        margin: 'auto',
      },
    },
    priceHolder: {
      display: 'flex',
    },
    discountPrice: {
      margin: '0 0 15px 0',
    },
    continuedBenefitsText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '25px',
      height: '35px',
      width: '300px',
      margin: '10px 0',
      backgroundColor: `${theme.palette.primary.bgLight}40`,
    },
    perkContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    perkContent: {
      display: 'flex',
      alignItems: 'center'
    },
    oldPriceStyle: {
      fontSize: '10px',
    },
    newPriceStyle: {
      fontSize: '20px',
    },
    chooseButton: {
      margin: '0 0 20px 0',
    },
    buttonHolder: {
      maxWidth: '332px',
      display: 'flex',
      '& .MuiButton-root': {
        width: '332px',
        height: '56px',
        borderRadius: 50,
      },
      '@media(max-width: 380px)': {
        width: '100%',
      },
    },
    planExplanation: {
      marginBottom: '49px',
    },
    textColor: {
      color: theme.palette.text.primary,
    },
    perksIcon: {
      alignItems: 'center',
    },
    hiddenPerks: {
      marginBottom: '64px',
    },
    perkName: {
      marginBottom: '14px',
    },
  });
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <div className={classes.root}>
      <Card
        style={{
          border: bestChoiceStyle ? `3px solid ${theme.palette.primary.bgMain}` : `1px solid ${theme.palette.primary.bgMain}`,
          background: 'transparent',
        }}
      >
        <CardContent>
          {bestChoiceStyle && (
            <Paper elevation={0} square className={classes.topBox}>
              <Typography variant="button" style={{ color: '#fff' }}>
                Opção mais escolhida
              </Typography>
              <div 
                style={{
                  height: '10px',
                  width: '100%',
                  background: '#fff',
                  borderRadius: '30px 30px 0 0'
                }}
              />
            </Paper>
          )}
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              style={{
                margin: bestChoiceStyle ? '20px 0 40px' : '54px 0 40px',
              }}
            >
              <Typography
                variant="h3"
                style={{
                  fontWeight: bestChoiceStyle && 'bold',
                  color: bestChoiceStyle
                    ? theme.palette.primary.bgLight
                    : theme.palette.text.primary,
                }}
              >
                {planName}
              </Typography>
            </Grid>
            {!disablePrice && (
              <>
                <Grid item xs={12}>
                  {noOldPrice && (
                    <div className={classes.priceHolder}>
                      <Typography variant="body2">de &nbsp;</Typography>
                      <Typography variant="body1">
                        {`R$ ${
                          noPrice ? '--' : oldCurrency
                        },`}

                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.oldPriceStyle}
                      >
                        {!noPrice && oldCents}
                      </Typography>
                      <Typography variant="body2">/mês</Typography>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} className={classes.discountPrice}>
                  {noPrice ? (
                    <div className={classes.priceHolder}>
                      <Typography
                        variant="h4"
                        style={{
                          color: theme.palette.text.primary,
                        }}
                      >
                        R$ --
                      </Typography>
                      <Typography variant="h5" style={{ paddingTop: 6 }} className={classes.textColor}>
                        /mês
                      </Typography>
                    </div>
                  ) : (
                    <div className={classes.priceHolder}>
                      <Typography
                        variant="h5"
                        style={{
                          color: theme.palette.text.primary,
                        }}
                      >
                        R$&nbsp;
                      </Typography>
                      <Typography
                        variant="h4"
                        style={{
                          color: theme.palette.text.primary,
                        }}
                      >
                        {`${newCurrency},`}
                      </Typography>
                      <Typography
                        variant="h4"
                        style={{
                          color: theme.palette.text.primary,
                        }}
                        className={classes.newPriceStyle}
                      >
                        {newCents}
                      </Typography>
                      <Typography className={classes.textColor} variant="h5">/mês</Typography>
                    </div>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12} className={classes.chooseButton}>
              <div className={classes.buttonHolder}>
                <Button
                  id={`button-${type}-${planName.toLowerCase()}`}
                  classes={{
                    label: `button-${type}-${planName.toLowerCase()}`,
                  }}
                  variant="outlined"
                  onClick={scrollBy}
                  style={{
                    backgroundColor: (purpleFriday && bestChoiceStyle)
                    && theme.palette.primary.bgLight,
                    border: (bestChoiceStyle && purpleFriday) ? '' : `1px solid ${theme.palette.primary.bgMain}`,
                  }}
                >
                  Calcular Plano
                </Button>
              </div>
            </Grid>
            <Grid item xs={9} className={classes.planExplanation}>
              <Typography className={classes.textColor} variant="body2" component="h4" align="center">
                {planDescription}
              </Typography>
            </Grid>
            <Grid
              container
              justify="center"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
              className={classes.planPerks}
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography variant="button" className={classes.textColor}>
                  características do plano
                </Typography>
              </Grid>
              {previousPlan && (
                <Paper elevation={0} square className={classes.continuedBenefitsText}>
                  <Typography variant="button" style={{ color: '#7c2e7e' }}>
                    {`Tudo do plano ${previousPlan} e mais`}
                  </Typography>
                </Paper>
              )}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {planPerks.map((perk) => (
                    <Grid item xs={12} className={classes.perkContainer}>
                      <div className={classes.perkContent}>
                        <CheckIcon
                          style={{ color: theme.palette.success.lightest, marginRight: 10 }}
                        />
                        <Typography className={classes.textColor} variant="body1">
                          {perk.label}
                        </Typography>
                      </div>
                      {perk.observation && (
                        <TooltipInfo
                          infoTitle={perk.observation.title}
                          infoBody={perk.observation.body}
                          colorIcon={theme.palette.primary.bgLight}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <div
              style={{
                height: (() => {
                  switch (planName) {
                    case 'Online':
                      return '41px';
                    case 'Private':
                      return '41px';
                    default:
                      return '41px';
                  }
                })(),
              }}
            />
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

InfoCardLanding.propTypes = {
  type: PropTypes.string.isRequired,
  planName: PropTypes.string.isRequired,
  noOldPrice: PropTypes.bool,
  oldPrice: PropTypes.number.isRequired,
  newPrice: PropTypes.number.isRequired,
  noPrice: PropTypes.bool.isRequired,
  planDescription: PropTypes.string.isRequired,
  planPerks: PropTypes.string.isRequired,
  accountingDetails: PropTypes.string.isRequired,
  benefitsDetails: PropTypes.string.isRequired,
  employeesDetails: PropTypes.string.isRequired,
  supportDetails: PropTypes.string.isRequired,
  bestChoiceStyle: PropTypes.bool,
  disablePrice: PropTypes.bool,
  purpleFriday: PropTypes.bool,
  previousPlan: PropTypes.string
};

InfoCardLanding.defaultProps = {
  disablePrice: false,
  noOldPrice: true,
  bestChoiceStyle: false,
  purpleFriday: false,
};

export default InfoCardLanding;