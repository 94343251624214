import { useTheme } from '@material-ui/styles';
import React from 'react';

export default function usePurpleFriday() {
  const theme = useTheme();

  const themePlansCard = {
    purpleFriday: {
      main: theme.palette.primary.bgDark,
      highlight: theme.palette.tnm.main,
      check: theme.palette.success.lightest,
      softPrimary: '#F5F2FF',
      light: theme.palette.tnm.main,
      lightPrimary: theme.palette.primary.light,
      textColor: '#F5F2FF',
      borderTab: '#F5F2FF',
      card: {
        background: 'transparent',
        main: '#8645FF',
        border: theme.palette.primary.dark,
        choiced: theme.palette.primary.dark,
      },
    },

    basic: {
      main: 'white',
      highlight: '#7C2E7E', // bgLight
      check: theme.palette.success.lightest,
      softPrimary: '', // soft
      light: '',
      lightPrimary: '', // .text.primary
      textColor: 'primary',
      borderTab: '#CCCBD2', // .action.disableBackground
      card: {
        background: 'white', // .secondary.contrastText
        main: '#7C2E7E', // bgLight
        border: '#CCCBD2', // .action.disableBackground
        choiced: '#7C2E7E',
      },
    },
  };

  return themePlansCard;
}
