export default {
  planPerksOnline: [
    {
      label: 'Plataforma "leve e intuitiva"',
      observation: {
        title: 'Plataforma "leve e intuitiva"',
        body: 
        'Todas as informações da sua empresa em um só lugar, gestão financeira sem complicações, calendário e notificações de atividades, emissão descomplicada de NF.'
      }
    },
    {
      label: 'Emissor de notas fiscais de serviço ilimitada',
      observation: {
        title: 'Emissor de notas fiscais de serviço ilimitada',
        body: 
        'Disponível através da plataforma da Conube. Consulte a lista de cidades habilitadas.'
      }
    },
    {
      label: 'Fechamento contábil (balanço) anual',
      observation: false
    },
    {
      label: 'Apuração mensal tributária',
      observation: false
    },
    {
      label: 'Obrigações acessórias contábil, tributária e trabalhista',
      observation: false
    },
    {
      label: 'Até 2 pró-labores mensais inclusos',
      observation: false
    },
    {
      label: 'Atendimento via chat',
      observation: false
    },
    {
      label: 'Atendimento via email',
      observation: false
    },
    {
      label: 'Atendimento das 9h às 18h',
      observation: false
    },
    {
      label: 'Valor mensal do holerite (folha de pagamento empregado e pro-labore) é de R$ 35,90 cada',
      observation: false
    },
    {
      label: 'Não aplicável para lucro real',
      observation: false
    },
    {
      label: 'Não aplicável para faturamento acima de R$ 150 mil/mês',
      observation: false
    },
  ],

  planPerksPrivate: [
    {
      label: 'Fechamento contábil (balanço) trimestral',
      observation: false
    },
    {
      label: 'Certificado Digital (e-CNPJ A1)',
      observation: false
    },
    {
      label: 'Atendimento via WhatsApp',
      observation: false
    },
  ],

  planPerksCorpo: [
    {
      label: 'Fechamento contábil (balanço) mensal',
      observation: false
    },
    {
      label: 'Até 3 pró-labores mensais inclusos',
      observation: false
    },
    {
      label: 'Atendimento por telefone',
      observation: false
    },
    {
      label: 'Especialista dedicado',
      observation: false
    },
    {
      label: 'Não aplicável para comércio',
      observation: false
    },
  ],

  onlineAccountingPerk: [
    {
      mark: true,
      observation: false,
      name: 'Contabilidade mensal',
      explanation: 'Incluso',
    },
    {
      mark: false,
      observation: {
        title: 'Relatórios financeiros mensais',
        body:
          'Extração e análise de dados e movimentações que serão úteis em tomadas de decisão para seus negócios.',
      },
      name: 'Relatórios financeiros mensais',
      explanation: 'A contratar',
    },
    {
      mark: true,
      observation: {
        title: 'Apuração mensal tributária',
        body:
          'Calculamos todos os impostos que incidem mensalmente sobre as atividades da sua empresa e disponibilizamos as guias para pagamento na nossa plataforma.',
      },
      name: 'Apuração mensal tributária',
      explanation: 'Incluso',
    },
  ],

  privateAccountingPerk: [
    {
      mark: true,
      observation: false,
      name: 'Contabilidade mensal',
      explanation: 'Incluso',
    },
    {
      mark: false,
      observation: {
        title: 'Relatórios financeiros mensais',
        body:
          'Extração e análise de dados e movimentações que serão úteis em tomadas de decisão para seus negócios.',
      },
      name: 'Relatórios financeiros mensais',
      explanation: 'Sob demanda',
    },
    {
      mark: true,
      observation: {
        title: 'Apuração mensal tributária',
        body:
          'Calculamos todos os impostos que incidem mensalmente sobre as atividades da sua empresa e disponibilizamos as guias para pagamento na nossa plataforma.',
      },
      name: 'Apuração mensal tributária',
      explanation: 'Incluso',
    },
  ],

  corporateAccountingPerk: [
    {
      mark: true,
      observation: false,
      name: 'Contabilidade mensal',
      explanation: 'Incluso',
    },
    {
      mark: true,
      observation: {
        title: 'Relatórios financeiros mensais',
        body:
          'Extração e análise de dados e movimentações que serão úteis em tomadas de decisão para seus negócios.',
      },
      name: 'Relatórios financeiros mensais',
      explanation: 'Incluso',
    },
    {
      mark: true,
      observation: {
        title: 'Apuração mensal tributária',
        body:
          'Calculamos todos os impostos que incidem mensalmente sobre as atividades da sua empresa e disponibilizamos as guias para pagamento na nossa plataforma.',
      },
      name: 'Apuração mensal tributária',
      explanation: 'Incluso',
    },
  ],

  onlineBenefitsPerks: [
    {
      mark: true,
      observation: false,
      name: 'Sócios',
      explanation: 'até 2 inclusos',
    },
    {
      mark: true,
      observation: {
        title: 'Obrigações acessórias contábil, tributária e trabalhista',
        body:
          'Declarações mensais, trimestrais e anuais, que devem ser entregues ao Governo para que sua empresa esteja regular.',
      },
      name: 'Obrigações acessórias contábil, tributária e trabalhista ',
      explanation: 'Incluso',
    },
    {
      mark: true,
      observation: {
        title: 'Emissor de NF (limitação de cidade)',
        body:
          'Emita notas fiscais diretamente pela plataforma. Essa funcionalidade só está disponível para os municípios integrados.Entre em contato para verificar a disponibilidade no seu município.',
      },
      name: 'Emissor de NF (limitação de cidade)',
      explanation: 'Incluso',
    },
    // TODO: Habilitar quando o Openbank for lançado
    // {
    //   mark: true,
    //   observation: {
    //     title: 'Emissor de boleto',
    //     body:
    //       'Nossa plataforma permitirá a emissão de boletos a um valor fixo.',
    //   },
    //   name: 'Emissor de boleto',
    //   explanation: 'Valor de R$ 4,00/boleto',
    // },
    {
      mark: false,
      observation: {
        title: 'Plataforma personalizada para sua equipe',
        body:
          'Forneça níveis de permissão para cada usuário, deixando o sistema sob medida para o seu negócio.',
      },
      name: 'Plataforma personalizada para sua equipe',
      explanation: 'A contratar',
    },
  ],

  privateBenefitsPerks: [
    {
      mark: true,
      observation: false,
      name: 'Sócios',
      explanation: 'até 2 inclusos',
    },
    {
      mark: true,
      observation: {
        title: 'Obrigações acessórias contábil, tributária e trabalhista',
        body:
          'Declarações mensais, trimestrais e anuais, que devem ser entregues ao Governo para que sua empresa esteja regular.',
      },
      name: 'Obrigações acessórias contábil, tributária e trabalhista ',
      explanation: 'Incluso',
    },
    {
      mark: true,
      observation: {
        title: 'Emissor de NF (limitação de cidade)',
        body:
          'Emita notas fiscais diretamente pela plataforma. Essa funcionalidade só está disponível para os municípios integrados.Entre em contato para verificar a disponibilidade no seu município.',
      },
      name: 'Emissor de NF (limitação de cidade)',
      explanation: 'Incluso',
    },
    // TODO: Habilitar quando o Openbank for lançado
    // {
    //   mark: true,
    //   observation: {
    //     title: 'Emissor de boleto',
    //     body:
    //       'Nossa plataforma permitirá a emissão de boletos a um valor fixo.',
    //   },
    //   name: 'Emissor de boleto',
    //   explanation: 'Valor de R$ 4,00/boleto',
    // },
    {
      mark: false,
      observation: {
        title: 'Plataforma personalizada para sua equipe',
        body:
          'Forneça níveis de permissão para cada usuário, deixando o sistema sob medida para o seu negócio.',
      },
      name: 'Plataforma personalizada para sua equipe',
      explanation: 'A contratar',
    },
  ],

  corporateBenefitsPerks: [
    {
      mark: true,
      observation: false,
      name: 'Sócios',
      explanation: 'até 2 inclusos',
    },
    {
      mark: true,
      observation: {
        title: 'Obrigações acessórias contábil, tributária e trabalhista',
        body:
          'Declarações mensais, trimestrais e anuais, que devem ser entregues ao Governo para que sua empresa esteja regular.',
      },
      name: 'Obrigações acessórias contábil, tributária e trabalhista ',
      explanation: 'Incluso',
    },
    {
      mark: true,
      observation: {
        title: 'Emissor de NF (limitação de cidade)',
        body:
          'Emita notas fiscais diretamente pela plataforma. Essa funcionalidade só está disponível para os municípios integrados.Entre em contato para verificar a disponibilidade no seu município.',
      },
      name: 'Emissor de NF (limitação de cidade)',
      explanation: 'Incluso',
    },
    // TODO: Habilitar quando o Openbank for lançado
    // {
    //   mark: true,
    //   observation: {
    //     title: 'Emissor de boleto',
    //     body:
    //       'Nossa plataforma permitirá a emissão de boletos a um valor fixo.',
    //   },
    //   name: 'Emissor de boleto',
    //   explanation: '5 inclusos. Valor de R$ 4,00/boleto',
    // },
    {
      mark: true,
      observation: {
        title: 'Plataforma personalizada para sua equipe',
        body:
          'Forneça níveis de permissão para cada usuário, deixando o sistema sob medida para o seu negócio.',
      },
      name: 'Plataforma personalizada para sua equipe',
      explanation: 'Incluso',
    },
  ],

  onlineEmployeesPerks: [
    {
      mark: false,
      observation: false,
      name: 'Folha de pagamento',
      explanation: 'R$ 35,90/holerite',
    },
    {
      mark: false,
      observation: {
        title: 'Pró-Labore',
        body:
          'Nada mais é do que o salário do sócio. É um valor determinado a ser pago mensalmente a um ou mais sócios da empresa.',
      },
      name: 'Pró-Labore',
      explanation: 'até 2 incluso',
    },
  ],

  privateEmployeesPerks: [
    {
      mark: false,
      observation: false,
      name: 'Folha de pagamento',
      explanation: 'R$ 35,90/holerite',
    },
    {
      mark: false,
      observation: {
        title: 'Pró-Labore',
        body:
          'Nada mais é do que o salário do sócio. É um valor determinado a ser pago mensalmente a um ou mais sócios da empresa.',
      },
      name: 'Pró-Labore',
      explanation: 'até 2 incluso',
    },
  ],

  corporateEmployeesPerks: [
    {
      mark: true,
      observation: false,
      name: 'Folha de pagamento',
      explanation: '2 empregados. Acima R$ 35,90/holerite',
    },
    {
      mark: false,
      observation: {
        title: 'Pró-Labore',
        body:
          'Nada mais é do que o salário do sócio. É um valor determinado a ser pago mensalmente a um ou mais sócios da empresa.',
      },
      name: 'Pró-Labore',
      explanation: 'até 3 incluso',
    },
  ],

  onlineSupportPerks: [
    {
      mark: true,
      observation: false,
      name: 'Atendimento email',
      explanation: 'Incluso',
    },
    {
      mark: true,
      observation: false,
      name: 'Atendimento chat',
      explanation: 'Incluso',
    },
    {
      mark: false,
      observation: false,
      name: 'Atendimento WhatsApp',
      explanation: 'A contratar',
    },
    {
      mark: false,
      observation: false,
      name: 'Atendimento Telefone',
      explanation: 'A contratar',
    },
    {
      mark: false,
      observation: false,
      name: 'Especialista dedicado',
      explanation: 'A contratar',
    },
  ],

  privateSupportPerks: [
    {
      mark: true,
      observation: false,
      name: 'Atendimento email',
      explanation: 'Incluso',
    },
    {
      mark: true,
      observation: false,
      name: 'Atendimento chat',
      explanation: 'Incluso',
    },
    {
      mark: true,
      observation: false,
      name: 'Atendimento WhatsApp',
      explanation: 'Incluso',
    },
    {
      mark: false,
      observation: false,
      name: 'Atendimento Telefone',
      explanation: 'A contratar',
    },
    {
      mark: false,
      observation: false,
      name: 'Especialista dedicado',
      explanation: 'A contratar',
    },
  ],

  corporateSupportPerks: [
    {
      mark: true,
      observation: false,
      name: 'Atendimento email',
      explanation: 'Incluso',
    },
    {
      mark: true,
      observation: false,
      name: 'Atendimento chat',
      explanation: 'Incluso',
    },
    {
      mark: true,
      observation: false,
      name: 'Atendimento WhatsApp',
      explanation: 'Incluso',
    },
    {
      mark: true,
      observation: false,
      name: 'Atendimento Telefone',
      explanation: 'Incluso',
    },
    {
      mark: true,
      observation: false,
      name: 'Especialista dedicado',
      explanation: 'Incluso',
    },
  ],
};
