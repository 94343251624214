import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ContentWrap from '../atoms/ContentWrap';

const pageBackground = {
  home: {
    backgroundColor: '#3A2870',
    backgroundImage: 'url(/webp/background-pattern-purple-svg.svg)',
    // background:'rgb(59,45,112)',
    // background: 'linear-gradient(315deg, rgba(59,45,112,1) 68%, rgba(75,60,144,1) 100%)',
  },
  'abrir-empresa': {
    backgroundColor: '#306E97',
    backgroundImage: 'url(/webp/background-pattern-blue.svg)',
  },
  'trocar-contador': {
    backgroundColor: '#30746A',
    backgroundImage: 'url(/webp/background-pattern-green.svg)',
  },
  solucoes: {
    backgroundColor: '#3A2870',
    backgroundImage: 'url(/webp/background-solucoes.svg)',
  },
  'quanto-custa': {
    backgroundColor: '#521E54',
    backgroundImage: 'url(/webp/test.svg)',
  },
  'purple-friday': {
    background: '#1A1738',
    backgroundImage: 'url(/images/purple-friday.svg)',
  },
};

const pageMobileBackground = {
  home: {
    // backgroundImage: 'none',
    background: 'rgb(59,45,112)',
    background: 'linear-gradient(52deg, rgba(59,45,112,1) 68%, rgba(75,60,144,1) 100%)',
  },
  'abrir-empresa': {
    // backgroundImage: 'none',
    background: 'rgb(48,110,151)',
    background: ' linear-gradient(52deg, rgba(48,110,151,1) 68%, rgba(47,128,182,1) 100%)',
  },
  'trocar-contador': {
    // backgroundImage: 'none',
    background: 'rgb(48,116,106)',
    background: 'linear-gradient(52deg, rgba(48,116,106,1) 68%, rgba(59,150,131,1) 100%)',
  },
  solucoes: {
    // backgroundImage: 'none',
    background: 'rgb(58,40,112)',
    background: 'linear-gradient(52deg, rgba(58,40,112,1) 68%, rgba(84,66,157,1) 100%)',

  },
  'quanto-custa': {
    // backgroundImage: 'none',
    background: 'rgb(82,30,84)',
    background: 'linear-gradient(52deg, rgba(82,30,84,1) 68%, rgba(117,59,130,1) 100%)',
  },
  'purple-friday': {
    background: '#1A1738',
    backgroundImage: 'url(/images/purple-friday.svg)',
  },
};

const HeroBackground = ({ page, children }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      height: '550px',
      borderRadius: '0px 0px 0px 100px',
      paddingTop: '115px',
      marginBottom: '50px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: page === 'home' ? 'left' : 'center',
      ...pageBackground[page],

      '& .text': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        justifyContent: 'center',

        '& .MuiTypography-root': {
          color: theme.palette.secondary.contrastText,
        },
      },

      '& .span': {
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0.3em',
        textTransform: 'uppercase',
      },

      '& .title, & .title strong': {
        lineHeight: '54px',
      },

      '@media screen and (max-width: 959px)': {
        borderRadius: '0px 0px 0px 50px',
        paddingTop: '75px',
        height: '410px',
        ...pageMobileBackground[page],
        // backgroundPosition: 'left',

        '& .title, & .title strong': { lineHeight: '32px' },
        '& .span': {
          fontSize: '14px',
          letterSpacing: '0.04em',
        },
        '& .MuiTypography-h3': { fontSize: '24px' },
      },
    },
    box: { height: '100%' },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <ContentWrap className={classes.box}>
        {children}
      </ContentWrap>
    </Box>
  );
};

HeroBackground.defaultProps = {
  page: 'home',
  children: <></>,
};

HeroBackground.propTypes = {
  page: PropTypes.string,
  children: PropTypes.element,
};

export default HeroBackground;
