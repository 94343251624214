/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Tooltip,
  ClickAwayListener,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconInfo from './IconInfo';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: '#1A1738',
  },
  tooltip: {
    backgroundColor: '#1A1738',
    padding: 16,
    borderRadius: 16,
    '& .MuiTypography-body2': {
      color: '#fff !important',
    },
    border: '1px solid #3A2870',
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const TooltipInfo = ({
  infoTitle,
  infoBody,
  leaveTouchDelay,
  placement,
  colorIcon,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <BootstrapTooltip
          {...rest}
          arrow
          placement={placement}
          open={open}
          onOpen={handleTooltipOpen}
          onClose={handleTooltipClose}
          leaveTouchDelay={leaveTouchDelay}
          title={(
            <>
              {infoTitle && (
                <Typography variant="body2">
                  <strong>{infoTitle}</strong>
                </Typography>
              )}
              <Typography variant="body2">{infoBody}</Typography>
            </>
          )}
        >
          <IconButton
            color="primary"
            size="small"
            onClick={handleTooltipOpen}
          >
            <IconInfo color={colorIcon} />
          </IconButton>
        </BootstrapTooltip>
      </ClickAwayListener>
    </>
  );
};

TooltipInfo.defaultProps = {
  placement: 'top',
  leaveTouchDelay: 15000,
  infoTitle: false,
  colorIcon: '#CB57F5',
};

TooltipInfo.propTypes = {
  infoTitle: PropTypes.string,
  infoBody: PropTypes.string.isRequired,
  placement: PropTypes.string,
  leaveTouchDelay: PropTypes.number,
  colorIcon: PropTypes.string,
};

export default TooltipInfo;
