import React from 'react';
import { MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import MenuPopper from '../atoms/MenuPopper';
import { integerFormat } from '../functions/Utils';
import { plans } from '../functions/Options';

const PlanSelectDropdown = ({
  menuAnchor, setMenuAnchor, handleChange, formData, isOpenCompany,
}) => {
  const isCommerce = formData.segment === 'Comércio';

  const revenues = Object.keys(plans[formData.segment] || plans.Online);

  const revenuePosition = revenues.findIndex((el) => el === formData.monthly);

  const renderSwitchAccountantPlans = () => (
    <>
      {!isCommerce && (
        <>
          {revenuePosition < 6 && (
            <MenuItem onClick={() => handleChange('Online')}>
              Plano Online
            </MenuItem>
          )}

          {revenuePosition >= 5 && (
            <MenuItem onClick={() => handleChange('Private')}>
              Plano Private
            </MenuItem>
          )}

          {revenuePosition >= 6 && (
            <MenuItem onClick={() => handleChange('Corporate')}>
              Plano Corporate
            </MenuItem>
          )}
        </>
      )}

      {isCommerce && (
        <>
          <MenuItem onClick={() => handleChange('Comércio')}>
            Plano Comércio
          </MenuItem>
        </>
      )}
    </>
  );

  const renderOpenCompanyPlans = () => (
    <>
      {!isCommerce && (
        <>
          <MenuItem onClick={() => handleChange('Online')}>
            Plano Online
          </MenuItem>
          <MenuItem onClick={() => handleChange('Private')}>
            Plano Private
          </MenuItem>
          <MenuItem onClick={() => handleChange('Corporate')}>
            Plano Corporate
          </MenuItem>
        </>
      )}

      {isCommerce && (
        <>
          <MenuItem onClick={() => handleChange('Comércio')}>
            Plano Comércio
          </MenuItem>
        </>
      )}
    </>
  );

  return (
    <MenuPopper menuAnchor={menuAnchor} setMenuAnchor={setMenuAnchor}>
      {isOpenCompany ? renderOpenCompanyPlans() : renderSwitchAccountantPlans()}
    </MenuPopper>
  );
};

PlanSelectDropdown.propTypes = {
  menuAnchor: PropTypes.string.isRequired,
  setMenuAnchor: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  formData: PropTypes.shape().isRequired,
  isOpenCompany: PropTypes.bool.isRequired,
};

export default PlanSelectDropdown;
