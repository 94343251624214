import React from 'react';
import PropTypes from 'prop-types';

const IconInfo = ({ color }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 2.6875C7.26777 2.6875 2.6875 7.26777 2.6875 13C2.6875 18.7322 7.26777 23.3125 13 23.3125C18.7322 23.3125 23.3125 18.7322 23.3125 13C23.3125 7.26777 18.7322 2.6875 13 2.6875ZM0.8125 13C0.8125 6.23223 6.23223 0.8125 13 0.8125C19.7678 0.8125 25.1875 6.23223 25.1875 13C25.1875 19.7678 19.7678 25.1875 13 25.1875C6.23223 25.1875 0.8125 19.7678 0.8125 13Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8125 13C10.8125 12.4822 11.2322 12.0625 11.75 12.0625H13C13.5178 12.0625 13.9375 12.4822 13.9375 13V19.25C13.9375 19.7678 13.5178 20.1875 13 20.1875C12.4822 20.1875 12.0625 19.7678 12.0625 19.25V13.9375H11.75C11.2322 13.9375 10.8125 13.5178 10.8125 13Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7432 7.43095C11.993 7.18113 12.3304 7.0625 12.625 7.0625C12.9505 7.0625 13.2372 7.22837 13.4053 7.48019C13.7861 7.59936 14.0625 7.95494 14.0625 8.37506C14.0625 8.64457 13.9945 9.01885 13.6942 9.31917C13.3939 9.61953 13.0196 9.68762 12.75 9.68762C12.2764 9.68762 11.9329 9.44053 11.7366 9.19505C11.6085 9.03495 11.5059 8.83129 11.4614 8.61054C11.4079 8.44394 11.3858 8.25597 11.419 8.05688C11.4646 7.78315 11.6005 7.57368 11.7432 7.43095Z"
      fill={color}
    />
  </svg>
);

IconInfo.propTypes = {
  color: PropTypes.string,
};

IconInfo.defaultProps = {
  color: '#CB57F5',
};

export default IconInfo;
