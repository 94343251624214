import React, { useEffect } from 'react';
import Main from '../../components/pages/Main';
import HowMuch from '../../components/pages/HowMuch';

const QuantoCusta = () => {
  return (
    <Main page="quanto-custa">
      <HowMuch />
    </Main>
  );
}

export default QuantoCusta;
