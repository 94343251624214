import React from 'react';

const IconCard = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5039 8.84766C10.5374 8.84766 9.75391 9.63116 9.75391 10.5977C9.75391 11.5642 10.5374 12.3477 11.5039 12.3477C12.4704 12.3477 13.2539 11.5642 13.2539 10.5977C13.2539 9.63116 12.4704 8.84766 11.5039 8.84766ZM8.25391 10.5977C8.25391 8.80273 9.70898 7.34766 11.5039 7.34766C13.2988 7.34766 14.7539 8.80273 14.7539 10.5977C14.7539 12.3926 13.2988 13.8477 11.5039 13.8477C9.70898 13.8477 8.25391 12.3926 8.25391 10.5977Z" fill="#7C2E7E"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.26562 6.0957C1.26562 4.58149 2.50141 3.3457 4.01562 3.3457H19.0156C20.5298 3.3457 21.7656 4.58149 21.7656 6.0957V13.9957C21.7656 14.4099 21.4298 14.7457 21.0156 14.7457C20.6014 14.7457 20.2656 14.4099 20.2656 13.9957V6.0957C20.2656 5.40992 19.7014 4.8457 19.0156 4.8457H4.01562C3.32984 4.8457 2.76562 5.40992 2.76562 6.0957V15.0957C2.76562 15.7815 3.32984 16.3457 4.01562 16.3457H12.3156C12.7298 16.3457 13.0656 16.6815 13.0656 17.0957C13.0656 17.5099 12.7298 17.8457 12.3156 17.8457H4.01562C2.50141 17.8457 1.26562 16.6099 1.26562 15.0957V6.0957Z" fill="#7C2E7E"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25391 14.0977C4.25391 13.6834 4.58969 13.3477 5.00391 13.3477H7.00391C7.41812 13.3477 7.75391 13.6834 7.75391 14.0977C7.75391 14.5119 7.41812 14.8477 7.00391 14.8477H5.00391C4.58969 14.8477 4.25391 14.5119 4.25391 14.0977Z" fill="#7C2E7E"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2539 7.09766C15.2539 6.68344 15.5897 6.34766 16.0039 6.34766H18.0039C18.4181 6.34766 18.7539 6.68344 18.7539 7.09766C18.7539 7.51187 18.4181 7.84766 18.0039 7.84766H16.0039C15.5897 7.84766 15.2539 7.51187 15.2539 7.09766Z" fill="#7C2E7E"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1529 14.9586C16.1746 15.3723 15.857 15.7253 15.4433 15.747C13.9192 15.8272 12.7539 17.0191 12.7539 18.4981C12.7539 19.9839 14.0181 21.2481 15.5039 21.2481C16.6142 21.2481 17.533 20.6182 17.9116 19.7096C18.0709 19.3273 18.51 19.1464 18.8924 19.3058C19.2747 19.4651 19.4555 19.9042 19.2962 20.2865C18.6748 21.7779 17.1936 22.7481 15.5039 22.7481C13.1897 22.7481 11.2539 20.8123 11.2539 18.4981C11.2539 16.177 13.0886 14.3689 15.3645 14.2491C15.7781 14.2273 16.1311 14.545 16.1529 14.9586Z" fill="#7C2E7E"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5039 13.6484C17.0181 13.6484 15.7539 14.9127 15.7539 16.3984C15.7539 17.8842 17.0181 19.1484 18.5039 19.1484C19.9897 19.1484 21.2539 17.8842 21.2539 16.3984C21.2539 14.9127 19.9897 13.6484 18.5039 13.6484ZM14.2539 16.3984C14.2539 14.0842 16.1897 12.1484 18.5039 12.1484C20.8181 12.1484 22.7539 14.0842 22.7539 16.3984C22.7539 18.7127 20.8181 20.6484 18.5039 20.6484C16.1897 20.6484 14.2539 18.7127 14.2539 16.3984Z" fill="#7C2E7E"/>
  </svg>
);

export default IconCard;
