import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const InputWrap = ({ hasMargin, children }) => {
  const useStyles = makeStyles({
    inputWrap: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '10px 30px',
      marginBottom: hasMargin ? '30px' : 0,
  
      '@media screen and (max-width: 959px)': {
        gridTemplateColumns: 'auto',
      }
    },
  });
  const classes = useStyles();

  return (
    <Box className={classes.inputWrap}>
      {children}
    </Box>
  );
};

InputWrap.defaultProps = {
  hasMargin: true,
  children: <></>,
};

InputWrap.propTypes = {
  hasMargin: PropTypes.bool,
  children: PropTypes.element,
};

export default InputWrap;
