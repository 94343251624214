import React, { useState } from 'react';
import {
  Typography,
  TextField,
  FormHelperText,
  MenuItem,
  Box,
  makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import PropTypes from 'prop-types';
import InputWrap from '../atoms/InputWrap';
import StyledButton from '../atoms/StyledButton';
import MaskedTextField from '../molecules/MaskedTextField';
import AutocompleteCityUF from './AutocompleteCityUF';
import {
  ActivityOptions,
  FormatOptions,
  SegmentOptions,
} from '../functions/Options';
import {
  EmailValidation,
  LengthValidation,
  NameValidation,
  ObjectValidation,
  PhoneValidation,
  LastNameValidation,
} from '../functions/FormValidation';
import Hubspot from '../functions/Hubspot';
import { getOpenCompanyPrice, getPlanName } from '../functions/CalculateEstimatedCosts';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '25px',

    '& .labels': {
      color: `${theme.palette.midGrey.main} !important`,
      marginTop: '30px',
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
      marginBottom: '20px',
    },
  },
  lastInputs: {
    marginBottom: '30px',

    '& .MuiFormControl-root, & .MuiAutocomplete-root': {
      width: '100%',
      marginTop: '10px',
    },
  },
  buttonWrap: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
  },
}));

const HowMuchOpenCompany = ({
  name,
  data,
  setData,
  setResultData,
  isLoading,
  setIsLoading,
  color,
  txtButton,
  purpleFriday,
}) => {
  const classes = useStyles();
  const [error, setError] = useState({});

  const validateFields = () => (
    ObjectValidation(data, 10)
    && LengthValidation(data)
    && NameValidation(data)
    && LastNameValidation(data)
    && PhoneValidation(data)
    && EmailValidation(data)
  );

  const isFormValid = () => {
    try {
      return validateFields();
    } catch (e) {
      return false;
    }
  };

  const validateForm = () => {
    try {
      if (validateFields()) {
        setError({});
      }
    } catch (e) {
      setError({ field: e.fileName, message: e.message });
      return false;
    }
    return true;
  };

  const changeData = (value, field) => {
    setData({ ...data, [field]: value });
    setError({});
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      setIsLoading(true);

      axios.get(`${process.env.GATSBY_ABRIR_EMPRESA_BASE_URL}/util/getprice`, {
        params: {
          state: data.state,
          regime: data.format,
          city: data.city,
          ignoreEqual: true,
          active: true,
        },
      })
        .then((response) => {
          if (
            response
            && response.data
            && response.data.plansList
            && response.data.plansList.length > 0
            && response.data.plansList[0].price
          ) {
            const result = {
              form: 'abrir-empresa',
              plan: {
                name: getPlanName(data.segment),
                price: getOpenCompanyPrice(data),
              },
              products: response.data.planProductsList ? response.data.planProductsList : [],
              totalValue: response.data.plansList[0].price,
            };

            // eslint-disable-next-line import/no-named-as-default-member
            Hubspot.sendHowMuchOpenCompany(result, data.email);

            setIsLoading(false);
            setResultData(result);
          } else {
            setIsLoading(false);
            setError({ field: '', message: 'Não foi possível calcular' });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError({ field: '', message: 'Não foi possível calcular' });
        });
    }
  };

  const handleCityUFChange = (value) => {
    if (value) {
      setData({
        ...data,
        state: value.stateInitial,
        city: value.code,
        cityName: value.name,
      });
    } else {
      setData({
        ...data,
        state: null,
        city: null,
        cityName: null,
      });
    }
  };

  return (
    <Box className={classes.root}>
      <form
        onSubmit={(e) => onSubmit(e)}
        className="FORM-QUANTO-CUSTA-ABRIR-EMPRESA"
        name={name}
      >
        <Typography variant="button" className="labels">Dados pessoais</Typography>
        <InputWrap>
          <TextField
            label="Nome"
            name="name"
            value={data.name}
            onChange={(e) => changeData(e.target.value, 'name')}
            error={error.field === 'name'}
            disabled={isLoading}
          />

          <TextField
            label="Sobrenome"
            name="lastName"
            value={data.lastName}
            onChange={(e) => changeData(e.target.value, 'lastName')}
            error={error.field === 'lastName'}
            disabled={isLoading}
          />

          <MaskedTextField
            onChange={(e) => changeData(e.target.value, 'phone')}
            mask="+55 (99) 99999-9999"
            textFieldProps={{
              label: 'Telefone',
              name: 'phone',
              type: 'tel',
              value: data.phone,
              floating: true,
              error: error.field === 'phone',
            }}
            InputProps={{
              disabled: isLoading,
            }}
          />

          <TextField
            label="E-mail"
            name="email"
            type="email"
            value={data.email}
            onChange={(e) => changeData(e.target.value, 'email')}
            error={error.field === 'email'}
            disabled={isLoading}
          />
        </InputWrap>

        <Typography variant="button" className="labels">Sobre sua empresa</Typography>
        <InputWrap hasMargin={false}>
          <TextField
            label="Qual será a atividade?"
            name="atividade"
            value={data.activity}
            onChange={(e) => changeData(e.target.value, 'activity')}
            disabled={isLoading}
            select
          >
            {ActivityOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Qual segmento de atuação?"
            name="segmento_atuacao"
            value={data.segment}
            onChange={(e) => changeData(e.target.value, 'segment')}
            disabled={isLoading}
            select
          >
            {SegmentOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </InputWrap>

        <Box className={classes.lastInputs}>
          <TextField
            label="Qual o formato jurídico?"
            name="formato_juridico"
            value={data.format}
            onChange={(e) => changeData(e.target.value, 'format')}
            disabled={isLoading}
            select
          >
            {FormatOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <AutocompleteCityUF
            onChange={(value) => handleCityUFChange(value)}
            value={{
              stateInitial: data.state,
              code: data.city,
              name: data.cityName,
            }}
            disabled={isLoading}
          />
        </Box>

        <FormHelperText>{error.message}</FormHelperText>
        <Box onClick={validateForm} className={classes.buttonWrap}>
          <StyledButton
            text={txtButton}
            type="submit"
            disabled={!isFormValid() || isLoading}
            variant={purpleFriday ? 'purpleFriday' : 'contained'}
            color={purpleFriday ? 'primary' : color}
            width="100%"
          />
        </Box>
      </form>
    </Box>
  );
};

HowMuchOpenCompany.defaultProps = {
  color: 'info',
  txtButton: 'Calcular plano',
};

export default HowMuchOpenCompany;
