import axios from 'axios';
import { getEconomyValue } from './CalculateEstimatedCosts';

const husbpotApiUrl = `${process.env.GATSBY_API_BASE_URL}/api/hubspot`;

const hubspot = axios.create({
  baseURL: husbpotApiUrl,
  headers: { accept: 'application/json', 'content-type': 'application/json' },
  validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
});

export const sendHowMuchOpenCompany = async (result, userEmail) => {
  try {
    const body = {
      userEmail,
      properties: {
        plano_recomendado: result.plan.name,
        valor_plano_recomendado: result.plan.price,
        valor_total_abertura: result.totalValue,
      },
    };

    result.products.map((product) => {
      if (product && product.name) {
        if (product.name === 'E-CPF') body.properties.valor_ecpf = product.price;

        if (product.name === 'Certificado Digital') body.properties.valor_ecnpj = product.price;

        if (product.name === 'Honorários') body.properties.valor_honorarios_contabeis = product.price;

        if (product.name === 'Taxa do DARE') body.properties.valor_taxas_governo = product.price;
      }
    });

    await hubspot.post('/contact/how-much-result', body);
  } catch (err) {
    console.error('Desculpe, ocorreu um erro. Tente novamente mais tarde!');
  }
};

export const sendHowMuchSwitchAccountant = async (result, userEmail) => {
  try {
    const body = {
      userEmail,
      properties: {
        plano_recomendado: result.plan.name,
        valor_plano_recomendado: result.plan.price,
        economia_anual_contabilidade: getEconomyValue(result.currentAccountant, result.plan.price),
      },
    };

    await hubspot.post('/contact/how-much-result', body);
  } catch (err) {
    console.error('Desculpe, ocorreu um erro. Tente novamente mais tarde!');
  }
};

export default {
  sendHowMuchOpenCompany,
  sendHowMuchSwitchAccountant,
};
