import React, { useState } from 'react';
import {
  Typography,
  IconButton,
  Box,
  Button,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import Spacer from '../atoms/Spacer';
import FormCard from '../atoms/FormCard';
import StyledButton from '../atoms/StyledButton';
import IconInfo from '../atoms/IconInfo';
import IconCard from '../atoms/IconCard';
import IconArrow from '../atoms/IconArrow';
import PlanSelectDropdown from '../molecules/PlanSelectDropdown';
import CreateAccountDrawer from './CreateAccountDrawer';
import { currencyFormat, integerFormat } from '../functions/Utils';
import Hubspot from '../functions/Hubspot';
import AllowedCities from '../../static/files/AllowedCities.json';
import ArrowLeft from '../../static/images/seta-esquerda.svg';
import ErrorImage from '../../static/images/mulher-duvida-erro.png';
import { getSwitchAccountPrice } from '../functions/CalculateEstimatedCosts';

const ResultSwitchAccount = ({
  resultData,
  setResultData,
  goBack,
  formData,
  setFormData,
  className,
  color,
}) => {
  const theme = useTheme();

  const colors = {
    info: {
      main: '#7C2E7E',
      highlight: theme.palette.primary.highlight,
      dark: '#521E54',
      background: '#FEEAFF',
      border: '#EBC4ED',
    },
    secondary: {
      main: theme.palette.info.main,
      highlight: theme.palette.info.highlight,
      dark: '#306E97',
      background: '#EFF9FF',
      border: '#D2E7F4',
    },
    success: {
      main: theme.palette.success.main,
      highlight: theme.palette.success.main,
      dark: '#521E54',
      background: '#E6FFFB',
      border: '#CEF0EB',
    },
  };

  const useStyles = makeStyles(() => ({
    desktop: {
      padding: '0 !important',

      display: 'block',

      '@media screen and (max-width: 970px)': {
        display: 'none',
      },
    },
    mobile: {
      display: 'none',

      '@media screen and (max-width: 970px)': {
        display: 'block',
      },
    },
    top: {
      padding: '30px 10px 20px',
      display: 'flex',
      alignItems: 'center',

      '@media screen and (max-width: 970px)': {
        padding: '0 0 20px 0',
      },
    },
    content: {
      padding: '30px 20px',
      backgroundColor: colors[color].background,
      borderRadius: '24px 24px 16px 16px',

      '& .text-center': {
        textAlign: 'center',
      },
    },
    infos: {
      minHeight: '430px',
      marginBottom: '20px',
      '& .price-title': {
        marginTop: '5px',
        color: `${colors[color].main} !important`,
      },
      '& .cities-message': {
        marginBottom: '35px',
      },
      '& .bottom-message': {
        textAlign: 'center',
        marginTop: '20px',

        '& strong': {
          color: colors[color].highlight,
        },
      },
    },
    totalValue: {
      display: 'grid',
      gridTemplateColumns: 'min-content auto max-content',
      alignItems: 'center',
      padding: '5px 20px 5px 5px',
      background: theme.palette.secondary.contrastText,
      border: `0.6px solid ${colors[color].border}`,
      borderRadius: '100px',

      '& .MuiTypography-root': {
        color: `${colors[color].main} !important`,

        '& span': {
          marginLeft: '5px',
        },

        '@media screen and (max-width: 970px)': {
          display: 'grid',
        },
      },

      '& .icon': {
        width: '42px',
        height: '42px',
        marginRight: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors[color].background,
        borderRadius: '100px',

        '& svg path': {
          fill: colors[color].main,
        },
      },
    },
    pricesList: {
      marginBottom: '20px',

      '& .price-line': {
        borderTop: `1px solid ${colors[color].border}`,
      },
      '& :nth-child(2)': {
        borderTop: '0 !important',
      },
    },
    priceLine: {
      display: 'grid',
      gridTemplateColumns: 'max-content auto',
      alignItems: 'center',
      padding: '10px 0',

      '& .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',

        '& svg': {
          fill: colors[color].highlight,
          marginRight: '10px',
          width: '20px',
          height: '20px',

          '& path': {
            fill: colors[color].highlight,
          },
        },

        '& .MuiTypography-body1': {
          color: colors[color].dark,
        },
      },

      '& .price': {
        textAlign: 'right',
        color: `${colors[color].main} !important`,
      },
    },
    divider: {
      margin: '15px 0',
      height: '1px',
      backgroundColor: colors[color].border,
    },
    errorImage: {
      width: '100%',
    },
    planSelect: {
      textTransform: 'unset',
      padding: '5px 12px',

      '& .MuiButton-label svg': {
        marginLeft: '10px',
        width: '11px',

        '& path': {
          stroke: colors[color].main,
        },
      },
    },
  }));
  const classes = useStyles();

  const [planSelectAnchor, setPlanSelectAnchor] = useState(null);
  const [createAccountOpen, setCreateAccountOpen] = useState(false);
  const isCityAllowed = AllowedCities.codes.includes(formData.city);
  const hasError = integerFormat(resultData.plan.price) <= 0;
  const hasEconomy = (integerFormat(resultData.currentAccountant)
    > integerFormat(resultData.plan.price));

  const getEconomyValue = () => {
    const economyValue = (
      integerFormat(resultData.currentAccountant) - integerFormat(resultData.plan.price)
    );
    return currencyFormat(economyValue * 12);
  };

  const handlePlanSelectChange = (value) => {
    const result = resultData;

    result.plan.name = value;
    result.plan.price = getSwitchAccountPrice(formData, value);
    // eslint-disable-next-line import/no-named-as-default-member
    Hubspot.sendHowMuchSwitchAccountant(result, formData.email);

    setResultData(result);
  };

  const renderCitiesMessage = () => (
    <Box className="cities-message">
      <Typography variant="body1">
        Em breve ampliaremos para diversos outros municípios brasileiros.
      </Typography>
      <Typography variant="body2">
        No momento, estamos atendendo apenas a cidade de São Paulo (SP).
      </Typography>
    </Box>
  );

  const renderBottomMessage = () => (
    <Typography variant="body2" className="bottom-message">
      O valor de mensalidade pago pelos serviços de Contabilidade Online da
      Conube, no momento da troca de contabilidade, será descontado somente com o
      <strong> início da prestação de serviço</strong>
      , que, por sua vez corresponde a data de transferência de responsabilidade
      contábil da empresa para a Conube.
    </Typography>
  );

  const renderErrorMessage = () => (
    <>
      <Typography variant="body1">
        Não conseguimos calcular os custos da forma correta,
        possivelmente por alguma combinação de escolhas que não atendemos no momento.
      </Typography>
      <Spacer size={20} />
      <Typography variant="body1">
        Em breve ampliaremos para diversas outras localidades e tipos de negócio.
      </Typography>
      <img src={ErrorImage} alt="Imagem de erro" className={classes.errorImage} />
    </>
  );

  const renderPlanSelect = () => (
    <>
      <Button
        className={classes.planSelect}
        onClick={(event) => setPlanSelectAnchor(event.currentTarget)}
      >
        <Typography variant="body1">{`Plano Conube ${resultData.plan.name}`}</Typography>
        <IconArrow />
      </Button>

      <PlanSelectDropdown
        menuAnchor={planSelectAnchor}
        setMenuAnchor={setPlanSelectAnchor}
        handleChange={handlePlanSelectChange}
        formData={formData}
        isOpenCompany={false}
      />
    </>
  );

  const renderTotalValue = () => (
    <Box className={classes.totalValue}>
      <Box className="icon">
        <IconCard />
      </Box>

      <Box>
        {renderPlanSelect()}
      </Box>

      <Typography variant="body1">
        <span>a partir de</span>
        <span>
          R$
          {' '}
          {resultData.plan.price}
        </span>
      </Typography>
    </Box>
  );

  const renderPriceLine = (description, price, key, hasIcon = true) => (
    <Box className={`${classes.priceLine} price-line`} key={key}>
      <Box>
        {hasIcon && <IconInfo />}
        <Typography variant="body1">{description}</Typography>
      </Box>
      <Typography variant="body1" className="price">{price}</Typography>
    </Box>
  );

  const renderEconomy = () => {
    if (hasEconomy) {
      return (
        <>
          <Typography variant="body1" className="price-title">
            Economia comparada a sua contabilidade atual
          </Typography>
          {renderPriceLine('Economia anual', getEconomyValue())}
        </>
      );
    }

    return (
      <Typography variant="body1" className="text-center">
        Com a plataforma de contabilidade online da Conube você automatiza os processos
        contábeis da sua empresa com mais agilidade e segurança.
      </Typography>
    );
  };

  const renderPrices = () => (
    <>
      <Typography variant="body1" className="price-title">
        Contabilidade mensal
      </Typography>
      {renderTotalValue()}
      <Box className={classes.divider} />
      {renderEconomy()}
    </>
  );

  const renderContent = () => {
    if (hasError) return renderErrorMessage();

    return (
      <>
        <Box className={classes.infos}>
          {!isCityAllowed && renderCitiesMessage()}
          {renderPrices()}
          {isCityAllowed && renderBottomMessage()}
        </Box>

        {isCityAllowed && (
          <StyledButton
            text="Começar agora"
            variant="contained"
            color={color}
            onClick={() => setCreateAccountOpen(true)}
            width="100%"
          />
        )}
      </>
    );
  };

  const renderContentWrap = () => (
    <>
      <Box className={classes.top}>
        <IconButton color="primary" onClick={goBack}>
          <img src={ArrowLeft} alt="Voltar para o formulário" />
        </IconButton>

        <Typography variant="h6">
          Custos para trocar de contador
        </Typography>
      </Box>

      <Box className={classes.content}>
        {renderContent()}
      </Box>
    </>
  );

  return (
    <Box className={className}>
      <FormCard hero className={classes.desktop}>
        {renderContentWrap()}
      </FormCard>

      <Box className={classes.mobile}>
        {renderContentWrap()}
      </Box>

      <CreateAccountDrawer
        open={createAccountOpen}
        setOpen={setCreateAccountOpen}
        process={resultData.form}
        step={1}
        openCompanyData={formData}
        setOpenCompanyData={setFormData}
        switchAccountantData={formData}
        setSwitchAccountantData={setFormData}
        goBack={goBack}
      />
    </Box>
  );
};

ResultSwitchAccount.propTypes = {
  resultData: PropTypes.shape({
    form: PropTypes.string.isRequired,
    plan: PropTypes.shape({
      name: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
    }),
    currentAccountant: PropTypes.string.isRequired,
  }).isRequired,
  setResultData: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    activity: PropTypes.string.isRequired,
    segment: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    cityName: PropTypes.string.isRequired,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string,
};

ResultSwitchAccount.defaultProps = {
  color: 'info',
};

export default ResultSwitchAccount;
