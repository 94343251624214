import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const FormCard = ({ color, className, hero, taNaModa, children, ...rest }) => {
  
  let padding = '30px 20px 30px'

  if(hero){
    padding = '35px 35px 42px'
  }else if(taNaModa){
    padding = '0px'
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: '16px',
      padding: padding,
      backgroundColor: theme.palette.secondary.contrastText,
      boxShadow: '0px 30px 70px rgba(58, 53, 104, 0.102)',
      position: 'relative',
      marginTop: hero ? '30px' : '0',
  
      '& .MuiTypography-button': {
        color: theme.palette[color].main,
      },
      '& .MuiTypography-body1': {
        color: theme.palette.text.primary,
      },
    },
  }));
  const classes = useStyles();

  return (
    <Box className={`${classes.root} ${className}`} {...rest}>
      {children}
    </Box>
  );
};

FormCard.defaultProps = {
  color: 'primary',
  className: '',
  hero: false,
  children: <></>,
  rest: {},
};

FormCard.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  hero: PropTypes.bool,
  children: PropTypes.element,
  rest: PropTypes.shape(),
};

export default FormCard;
