import React, { useState } from 'react';
import {
  Typography,
  TextField,
  FormHelperText,
  MenuItem,
  Box,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import InputWrap from '../atoms/InputWrap';
import StyledButton from '../atoms/StyledButton';
import MaskedTextField from '../molecules/MaskedTextField';
import AutocompleteCityUF from './AutocompleteCityUF';
import { SegmentOptions, monthlyInvoicing, monthlyInvoicingPrice } from '../functions/Options';
import {
  EmailValidation,
  LengthValidation,
  NameValidation,
  ObjectValidation,
  PhoneValidation,
  LastNameValidation,
  CnpjValidation,
} from '../functions/FormValidation';
import Hubspot from '../functions/Hubspot';
import { getSwitchAccountPrice, getPlanName } from '../functions/CalculateEstimatedCosts';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '25px',

    '@media screen and (max-width: 970px)': {
      paddingBottom: '50px',
    },

    '& .labels': {
      color: `${theme.palette.midGrey.main} !important`,
      marginTop: '30px',
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
      marginBottom: '20px',
    },
  },
  buttonWrap: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
  },
  inputSelf: {
    '& .MuiFormControl-root, & .MuiAutocomplete-root': {
      width: '100%',
      margin: '10px 0',
    },
  },
}));

const HowMuchSwitchAccountant = ({
  name,
  data,
  setData,
  setResultData,
  isLoading,
  setIsLoading,
  color,
  txtButton,
  purpleFriday,
}) => {
  const classes = useStyles();
  const [error, setError] = useState({});

  const validateFields = () => (
    ObjectValidation(data, 12)
    && LengthValidation(data)
    && NameValidation(data)
    && LastNameValidation(data)
    && PhoneValidation(data)
    && EmailValidation(data)
    && CnpjValidation(data)
  );

  const isFormValid = () => {
    try {
      return validateFields();
    } catch (e) {
      return false;
    }
  };

  const validateForm = () => {
    try {
      if (validateFields()) {
        setError({});
      }
    } catch (e) {
      setError({ field: e.fileName, message: e.message });
      return false;
    }
    return true;
  };

  const changeData = (value, field) => {
    setData({ ...data, [field]: value });
    setError({});
  };

  const currencyFormat = (value, field) => {
    const currencyValue = `R$ ${value.replace(/\D/g, '')
      .replace(/\B(?=(\d{2}$)+(?!\d))/g, ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
    changeData(currencyValue, field);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      setIsLoading(true);

      const result = {
        form: 'trocar-contador',
        plan: {
          name: getPlanName(data.segment, data.monthly),
          price: getSwitchAccountPrice(data, data.segment),
        },
        currentAccountant: data.price.replace('R$ ', ''),
      };

      setTimeout(() => {
        Hubspot.sendHowMuchSwitchAccountant(result, data.email);
        setIsLoading(false);
        setResultData(result);
      }, 800);
    }
  };

  const handleCityUFChange = (value) => {
    if (value) {
      setData({
        ...data,
        state: value.stateInitial,
        city: value.code,
        cityName: value.name,
      });
    } else {
      setData({
        ...data,
        state: null,
        city: null,
        cityName: null,
      });
    }
  };

  return (
    <Box className={classes.root}>
      <form
        onSubmit={(e) => onSubmit(e)}
        className="FORM-QUANTO-CUSTA-TROCAR-CONTADOR"
        name={name}
      >
        <Typography variant="button" className="labels">Dados pessoais</Typography>
        <InputWrap>
          <TextField
            label="Nome"
            name="name"
            value={data.name}
            onChange={(e) => changeData(e.target.value, 'name')}
            error={error.field === 'name'}
            disabled={isLoading}
          />

          <TextField
            label="Sobrenome"
            name="lastName"
            value={data.lastName}
            onChange={(e) => changeData(e.target.value, 'lastName')}
            error={error.field === 'lastName'}
            disabled={isLoading}
          />

          <MaskedTextField
            onChange={(e) => changeData(e.target.value, 'phone')}
            mask="+55 (99) 99999-9999"
            textFieldProps={{
              label: 'Telefone',
              name: 'phone',
              type: 'tel',
              value: data.phone,
              floating: true,
              error: error.field === 'phone',
            }}
            InputProps={{
              disabled: isLoading,
            }}
          />

          <TextField
            label="E-mail"
            name="email"
            type="email"
            value={data.email}
            onChange={(e) => changeData(e.target.value, 'email')}
            error={error.field === 'email'}
            disabled={isLoading}
          />
        </InputWrap>

        <Typography variant="button" className="labels">Sobre sua empresa</Typography>
        <InputWrap hasMargin={false}>
          <TextField
            label="Qual segmento de atuação?"
            name="segmento_atuacao"
            value={data.segment}
            onChange={(e) => changeData(e.target.value, 'segment')}
            disabled={isLoading}
            select
          >
            {SegmentOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Mensalidade contabilidade atual"
            name="valor_contabilidade_atual"
            value={data.price}
            onChange={(e) => currencyFormat(e.target.value, 'price')}
            error={error.field === 'price'}
            disabled={isLoading}
          />

          <TextField
            label="Faturamento estimado"
            name="faturamento_mensal_estimado"
            value={data.monthly}
            onChange={(e) => changeData(e.target.value, 'monthly')}
            disabled={isLoading}
            select
          >
            {monthlyInvoicing.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <AutocompleteCityUF
            onChange={(value) => handleCityUFChange(value)}
            value={{
              stateInitial: data.state,
              code: data.city,
              name: data.cityName,
            }}
            disabled={isLoading}
          />

          <TextField
            label="Número de funcionários"
            name="qtde_funcionarios"
            type="number"
            value={data.contributors}
            onChange={(e) => changeData(e.target.value, 'contributors')}
            error={error.field === 'contributors'}
            disabled={isLoading}
          />

          <TextField
            label="Número de contas bancárias"
            name="qtde_contas_bancarias"
            type="number"
            value={data.bankAccounts}
            onChange={(e) => changeData(e.target.value, 'bankAccounts')}
            error={error.field === 'bankAccounts'}
            disabled={isLoading}
          />
        </InputWrap>
        <Box className={classes.inputSelf}>
          <MaskedTextField
            onChange={(e) => changeData(e.target.value, 'cnpj')}
            mask="99.999.999/9999-99"
            textFieldProps={{
              label: 'CNPJ',
              name: 'cnpj',
              type: 'text',
              value: data.cnpj,
              floating: true,
              error: error.field === 'cnpj',
            }}
            InputProps={{
              disabled: isLoading,
            }}
          />
        </Box>
        <FormHelperText>{error.message}</FormHelperText>

        <Box onClick={validateForm} className={classes.buttonWrap}>
          <StyledButton
            text={txtButton}
            type="submit"
            disabled={!isFormValid() || isLoading}
            variant={purpleFriday ? 'purpleFriday' : 'contained'}
            color={purpleFriday ? 'primary' : color}
            width="100%"
          />
        </Box>
      </form>
    </Box>
  );
};

HowMuchSwitchAccountant.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  setData: PropTypes.func.isRequired,
  setResultData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  color: PropTypes.string,
  txtButton: PropTypes.string,
  purpleFriday: PropTypes.bool,
};

HowMuchSwitchAccountant.defaultProps = {
  color: 'info',
  txtButton: 'Calcular plano',
  purpleFriday: false,
};

export default HowMuchSwitchAccountant;
