import React, { useState } from 'react';
import {
  Box, Tabs, Tab, makeStyles,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';

import FormCard from '../atoms/FormCard';
import CenteredLoading from '../atoms/CenteredLoading';
import StyledDrawer from '../atoms/StyledDrawer';
import HowMuchOpenCompany from './HowMuchOpenCompany';
import HowMuchSwitchAccountant from './HowMuchSwitchAccountant';
import ResultSwitchAccount from './ResultSwitchAccount';
import ResultOpenCompany from './ResultOpenCompany';

const HowMuchFormHero = ({
  open, setOpen, themeType
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      position: 'relative',

      '& .MuiTab-root': {
        padding: '10px 0 25px',
      },
      '& .MuiTab-wrapper': {
        ...theme.typography.body1,
        textTransform: 'none',
        color: 'inherit',
      },
      '& .MuiTab-textColorInherit': {
        color: theme.palette.midGrey.main,
      },
      '& .Mui-selected': {
        color: themeType.highlight,
      },
      '& .MuiTabs-indicator': {
        backgroundColor: themeType.highlight,
      },

      '& .loaded': {
        opacity: '1',
        transition: '0.2s',
      },
      '& .loading': {
        opacity: '0.5',
        transition: '0.2s',
      },
    },
    desktop: {
      display: 'block',

      '@media screen and (max-width: 970px)': {
        display: 'none',
      },
    },
    hideOverflow: {
      '& .react-swipeable-view-container div': {
        overflow: 'hidden',
      },
    },
  }));

  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [resultData, setResultData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openCompanyData, setOpenCompanyData] = useState({});
  const [switchAccountantData, setSwitchAccountantData] = useState({});

  const handleResultBack = () => {
    setResultData(null);
    setOpenCompanyData({});
    setSwitchAccountantData({});
  };

  const renderContent = () => (
    <>
      <Box className={isLoading ? 'loading' : 'loaded'}>
        <Tabs
          value={tabValue}
          onChange={(event, value) => setTabValue(value)}
          variant="fullWidth"
          centered
        >
          <Tab
            label="Estou abrindo empresa"
            disableRipple
            value={0}
            disabled={isLoading}
          />
          <Tab
            label="Quero trocar de contador"
            disableRipple
            value={1}
            disabled={isLoading}
          />
        </Tabs>

        <SwipeableViews
          index={tabValue}
          onChangeIndex={setTabValue}
          className={classes.hideOverflow}
        >
          <div>
            <HowMuchOpenCompany
              name="Quanto Custa | Abrir Empresa"
              data={openCompanyData}
              setData={setOpenCompanyData}
              setResultData={setResultData}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </div>
          <div>
            <HowMuchSwitchAccountant
              name="Quanto Custa | Trocar de Contador"
              data={switchAccountantData}
              setData={setSwitchAccountantData}
              setResultData={setResultData}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </div>
        </SwipeableViews>
      </Box>
      {isLoading && <CenteredLoading />}
    </>
  );

  const renderResult = () => {
    if (resultData.form === 'abrir-empresa') {
      return (
        <ResultOpenCompany
          resultData={resultData}
          setResultData={setResultData}
          goBack={handleResultBack}
          formData={openCompanyData}
          setFormData={setOpenCompanyData}
          className={classes.desktop}
          isOpenCompany
          color="secondary"
        />
      );
    }

    return (
      <ResultSwitchAccount
        resultData={resultData}
        setResultData={setResultData}
        goBack={handleResultBack}
        formData={switchAccountantData}
        setFormData={setSwitchAccountantData}
      />
    );
  };

  return (
    <>
      {!resultData && (
        <>
          <FormCard hero className={`${classes.root} ${classes.desktop}`}>
            {renderContent()}
          </FormCard>
        </>
      )}

      {resultData && !isLoading && (openCompanyData.city || switchAccountantData.city) && (
        <>
          {renderResult()}
        </>
      )}

      <StyledDrawer
        open={open}
        setOpen={setOpen}
        width="84vw"
        borderRadius="0"
      >
        {!resultData && (
          <Box className={classes.root}>
            {renderContent()}
          </Box>
        )}

        {resultData && !isLoading && (openCompanyData.city || switchAccountantData.city) && (
          <>
            {renderResult()}
          </>
        )}
      </StyledDrawer>
    </>
  );
};

HowMuchFormHero.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default HowMuchFormHero;
