import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, makeStyles } from '@material-ui/core';
import Spacer from '../atoms/Spacer';
import StyledButton from '../atoms/StyledButton';
import HeroBackground from '../molecules/HeroBackground';
import HowMuchFormHero from './HowMuchFormHero';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',

    '& .span': {
      color: `${theme.palette.primary.highlight} !important`,
    },

    '& .title strong': {
      color: theme.palette.primary.highlight,
    },
  },
  description: {
    width: '75%',
  },
  button: {
    display: 'none',
    maxWidth: '85%',

    '@media screen and (max-width: 959px)': {
      display: 'block',
    },
  },
}));

const HowMuchHero = ({ themeType }) => {
  const classes = useStyles();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  return (
    <HeroBackground page="quanto-custa">
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={6} xs={12} className="text">
          <Typography className="span">Contabilidade sob medida</Typography>

          <Typography variant="h3" component="h1" className="title">
            Escolha o serviço e tenha<br />
            uma estimativa de custos<br />
            para começar
          </Typography>

          <Spacer size={20} />

          <Typography variant="body1" className={classes.description}>
            Com essa calculadora online e fácil de usar você descobre os custos
            para abrir sua empresa ou pode fazer uma simulação de quanto irá
            economizar com contabilidade
          </Typography>

          <Spacer size={20} />

          <Box className={classes.button}>
            <StyledButton
              text="Saiba quanto custa"
              variant="contained"
              color="white"
              width="100%"
              onClick={() => setMobileDrawerOpen(!mobileDrawerOpen)}
              style={{ color: '#7C2E7E' }}
            />
          </Box>
        </Grid>

        <Grid item md={6} xs={12}>
          <HowMuchFormHero open={mobileDrawerOpen} setOpen={setMobileDrawerOpen} themeType={themeType} />
        </Grid>
      </Grid>
    </HeroBackground>
  );
};

HowMuchHero.propTypes = {
  themeType: PropTypes.shape({}).isRequired
}

export default HowMuchHero;
