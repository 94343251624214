import React from 'react';
import usePurpleFriday from '../../themes/usePurpleFriday';
import Spacer from '../atoms/Spacer';
import HowMuchHero from '../organisms/HowMuchHero';
import PlansCard from '../organisms/PlansCard';
import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import WhatsAppIcon from '../../static/images/whatsapp.svg.webp';

const useStyles = makeStyles((theme) => ({
  whatsappButton: {
    position: 'fixed',
    bottom: '100px', 
    right: '0px',
    zIndex: '1000',
    padding: '5px',
    width: '60px',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: '#2c3e50',
  
    '@media screen and (max-width: 959px)': {
      width: '50px',
      bottom: '120px', 
      right: '0px',
    },
  },
  whatsappButtonIcon: {
    width: '40px', 
    height: '40px',
    left: '20px', 

    '@media screen and (max-width: 959px)': {
      width: '35px', 
      height: '35px',
    },
  }
}));

const HowMuch = () => {
  const classes = useStyles();
  const { basic } = usePurpleFriday();

  return (
    <>
      <HowMuchHero themeType={basic} />
      <PlansCard themeType={basic} />

      <div 
        className={classes.whatsappButton}
      >
        <a
          href="https://api.whatsapp.com/send/?phone=5511995362311&text&type=phone_number&app_absent=0"
          target="_blank"
          rel="noopener noreferrer"
          // className={classes.whatsappButtonIcon}
        >
          <img src={WhatsAppIcon} className={classes.whatsappButtonIcon} alt="WhatsApp"/>
        </a>
      </div>

      <Spacer size={100} />
    </>
  );
};

export default HowMuch;
